<template>
    <div>
        <b-modal
        id="modalDriverEdit"
        title="Изменить исполнителя"
        size="lg"
        hide-footer no-enforce-focus
        :no-close-on-backdrop="true"
        @show="Dates()"
        @shown="detectStartTime"
        @hidden="detectEndTime"
        >
            <b-row class="d-flex justify-content-center flex-column">
                <b-col cols="12" class="p-1 m-1 border-color">
                    <h5><strong>Личная информация</strong></h5>
                    <b-row>
                        <b-col cols="4" class="pr-1 border-right">
                            <label for="">Подразделение</label>
                            <v-select v-model="driver.division_id" :reduce="option => option.id"  placeholder="Подразделение" label="name" :options="divisions" class="select-size-md" />
                            <label for="">Фамилия</label>
                            <b-input v-model="driver.last_name" placeholder="Фамилия" />
                            <label for="">Имя</label>
                            <b-input v-model="driver.first_name" placeholder="Имя" />
                        </b-col>
                        <b-col cols="4" class="pl-1 border-right">
                            <label for="">Отчество</label>
                            <b-input v-model="driver.patronymic" placeholder="Отчество" />
                                <label for="example-input">Дата рождения</label>
                                <b-input-group class="">
                                <b-form-input
                                    id="example-input"
                                    v-model="driver.date_of_birth"
                                    type="text"
                                    placeholder="YYYY-MM-DD"
                                    autocomplete="off"
                                    show-decade-nav
                                />
                                <b-input-group-append>
                                    <b-form-datepicker
                                    v-model="driver.date_of_birth"
                                    show-decade-nav
                                    button-only
                                    :max="currDate"
                                    label-no-date-selected="Выберите дату"
                                    right
                                    locale="ru"
                                    aria-controls="example-input"
                                    @context="onContext"
                                    style="height: 38px;"
                                    />
                                </b-input-group-append>
                                </b-input-group>
                                <div v-if="driver.date_of_birth > currDate" style="color: red;">Ошибка! Водитель не может быть младше 18 лет!</div>
                                <label for="" class="mb-1">Пол</label>
                                <div class="d-flex justify-content-between adaptiv">
                                    <b-form-radio
                                    name="some-radios"
                                    v-for="gender in genders"
                                    :key="gender.id"
                                    :value="gender.id"
                                    v-model="driver.gender"
                                    >
                                        {{gender.name}}
                                    </b-form-radio>
                                </div>
                        </b-col>
                        <b-col>
                            <h5 class="mb-3"><strong>Контакная информация</strong></h5>
                            <label for="">Телефон</label>
                            <b-input v-model="driver.phone" placeholder="992927777777" />
                            <label for="">Дополнительный номер</label>
                            <b-input v-model="driver.contact_number" placeholder="992927777777" />
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" class="border-color p-1 m-1">
                    <div class="d-flex justify-content-between">
                        <h5><strong>Удостоверение личности</strong></h5>
                        <h5 style="margin-right: 65px;" class="car"><strong>Водительское удостоверение</strong></h5>
                    </div>
                    <b-row>
                        <b-col cols="8" class="pr-1 border-right">
                            <label for="">Номер удостоверения личности</label>
                            <b-input v-model="driver.serial_number_passport" type="text" placeholder="(1 буква 8 цифр)" v-mask="'A########'"/>
                            <b-row>
                                <b-col cols="6" class="pr-1 border-right mt-1">
                                <label for="example-input">Действителен до</label>
                                    <b-input-group class="">
                                    <b-form-input
                                        id="example-input"
                                        v-model="driver.expirated_passport"
                                        type="text"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        show-decade-nav
                                    />
                                    <b-input-group-append>
                                        <b-form-datepicker
                                        v-model="driver.expirated_passport"
                                        show-decade-nav
                                        button-only
                                        right
                                        locale="ru"
                                        aria-controls="example-input"
                                        @context="onContext"
                                        style="height: 38px;"
                                        />
                                    </b-input-group-append>
                                    </b-input-group>
                                    <label for="">Адрес</label>
                                    <b-input v-model="driver.address" class="form-control" placeholder="Адрес" />
                                </b-col>
                                <b-col cols="6" class="pl-1 mt-1">
                                    <label for="">Орган, выдающий документ</label>
                                    <v-select v-model="driver.passport_office_id" :reduce="option => option.id" placeholder="Орган, выдающий документ" label="name" :options="passport_offices" class="select-size-md" />
                                    <label for="" class="">Район проживания</label>
                                    <v-select v-model="driver.district_id" :reduce="option => option.id" placeholder="Район" label="name" :options="districts" class="select-size-md" />
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4">
                            <label for="">Номер вод.удостоверения</label>
                            <b-input v-model="driver.serials_number" type="text" placeholder="(2 буквы 7 цифр)" v-mask="'AA#######'"/>
                            <label for="example-input" style="margin-top: 16px">Действителен до</label>
                            <b-input-group class="">
                            <b-form-input
                                id="example-input"
                                v-model="driver.expirated_driver_license"
                                type="text"
                                
                                placeholder="YYYY-MM-DD"
                                autocomplete="off"
                                show-decade-nav
                            />
                            <b-input-group-append>
                                <b-form-datepicker
                                v-model="driver.expirated_driver_license"
                                show-decade-nav
                                button-only
                                right
                                locale="ru"
                                aria-controls="example-input"
                                @context="onContext"
                                style="height: 38px;"
                                />
                            </b-input-group-append>
                            </b-input-group>
                            <b-button @click="editCar()"
                                style="margin-top: 24px; width: 100%;" variant="outline-primary"
                                >
                                Автомобиль
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <div class="d-flex align-items-center">
                                <div class="">
                                    <b-form-radio
                                    name="some-radio"
                                    class="mt-1"
                                    v-for="status in statuses"
                                    :key="status.value"
                                    :value="status.value"
                                    v-model="driver.is_active"
                                    >
                                        {{status.name}}
                                    </b-form-radio>
                                </div>
                                <b-input disabled v-model="carInf"  class="mt-2 ml-3"></b-input>
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <label for="" class="mt-2">Дополнительная информация</label>
                            <b-form-textarea  v-model="driver.dop_info" placeholder="Введите текст"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div :style="`width: 200px; ${driver.car_id || carId  ? 'display: none' : 'display: block'}`">
                <label for="1" class="check"><input id="1" type="checkbox" v-model="checked">
                    <div class="without">Без автомобиля</div>
                </label>
            </div>
            <b-row class="mb-1">
                <b-col class="d-flex justify-content-end">
                    <b-button
                    :disabled="submitButtonDisabled"
                    @click="editDriver()"
                    variant="primary">Изменить</b-button>
                    <b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <modal-car-edit :id="id" :fuel_types="fuel_types"/>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mask } from "vue-the-mask"
import modalCarEdit from "@/views/component/Modal/ModalCar/modalCarEdit.vue"

export default {
    props: ['driver_id', 'carId', 'car_info'],
    directives: { mask },
    components: {
        vSelect,
        ToastificationContent,
        modalCarEdit
    },
    data(){
        return {
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            driver: {
                division_id: undefined,
                first_name: undefined,
                last_name: undefined,
                patronymic: undefined,
                phone: undefined,
                contact_number: undefined,
                date_of_birth: undefined,
                serials_number: undefined,
                expirated_driver_license: undefined,
                gender: undefined,
                is_active: undefined,
                serial_number_passport: undefined,
                expirated_passport: undefined,
                district_id: undefined,
                passport_office_id: undefined,
                address: undefined,
                dop_info: undefined
            },
            formatted: '',
            selected: '',
            passport_offices: undefined,
            districts: undefined,
            divisions: undefined,
            driver_car: null,
            id: '',
            divisions_select: undefined,
            district_select: undefined,
            passport_select: undefined,
            currDate: undefined,
            DateValidator: undefined,
            checked: false,
            genders: [
                {id: 0, name: 'Женский'},
                {id: 1, name: 'Мужской'}
            ],
            statuses: [
                {value: 1, name: 'Работает'},
                {value: 0, name: 'Заблокировать'}
            ],
            submitButtonDisabled: false,
            fuel_types: []
        }
    },
    methods: {
        carInf() {
            return this.car_info
        },
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
            })
            .catch((err) => {
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'driverEditForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        opened(){
            console.log(this.driver_id);
            this.carInf = undefined
            this.$http
                .get(`drivers/${this.driver_id}/edit`)
                .then(res => {
                    this.driver = res.data
                    if (this.driver.car_id == null) {
                        this.checked = true
                    }
                    if (this.driver.driver_cars.length > 0) {
                        this.driver.driver_cars.forEach(el => {
                            if (el.active == 1) {
                                this.driver_car = el
                                this.id = el.id
                                this.carInf = el.car_brand + ' ' + el.model + ' (' + el.car_number + ')'
                            }
                        })
                    }
            })
         this.$http
        .get('drivers/data')
        .then(res =>{
            this.passport_offices = res.data.passport_offices
            this.districts = res.data.districts
            this.divisions = res.data.divisions
            console.log(this.divisions, 'wefwef');
        })
        },
        editDriver(){
            if (this.carId) {
                this.driver.car_id = this.carId
            }
            this.driver.dop_info = this.driver.dop_info ? this.driver.dop_info : undefined
            this.driver.car_id = this.driver.car_id ? this.driver.car_id : undefined
            this.driver.patronymic = this.driver.patronymic ? this.driver.patronymic : undefined
            this.driver.contact_number = this.driver.contact_number ? this.driver.contact_number : undefined
            this.driver.serials_number = this.driver.serials_number ? this.driver.serials_number : undefined
            this.driver.expirated_driver_license = this.driver.expirated_driver_license ? this.driver.expirated_driver_license : undefined
            this.driver.serial_number_passport = this.driver.serial_number_passport ? this.driver.serial_number_passport : undefined
            this.driver.expirated_passport = this.driver.expirated_passport ? this.driver.expirated_passport : undefined
            this.submitButtonDisabled = true
            this.driver.passport_office_id = this.driver.passport_office_id ? this.passport_office_id : undefined
            this.driver.address = this.driver.address ? this.driver.address : undefined
            this.driver.district_id = this.driver.district_id ? this.driver.district_id : undefined
            this.$http
                .patch(`drivers/${this.driver_id}`, this.driver)
                .then(res => {
                    this.$bvModal.hide('modalDriverEdit')
                    this.closeModal()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Данные успешно изменены!`,
                        },
                    })
                    this.$emit('refresh', "getphoto")
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        onContext(ctx) {
            this.selected = ctx.selectedYMD
            this.formatted = ctx.selectedFormatted
        },
        closeModal(){
            this.drivers = {
                division_id: '',
                first_name: '',
                last_name: '',
                patronymic: undefined,
                phone: '',
                contact_number: undefined,
                date_of_birth: '',
                serials_number: undefined,
                expirated_driver_license: undefined,
                gender: '',
                serial_number_passport: undefined,
                expirated_passport: undefined,
                district_id: '',
                passport_office_id: undefined,
                address: '',
                transport: undefined,
                dop_info: undefined
            },
            this.divisions_select = undefined,
            this. car_select = undefined,
            this.district_select = undefined,
            this.passport_select = undefined
            this.$bvModal.hide('modalDriverEdit')
            this.$emit('editCar', null)
        },
        editCar(){
            if (this.driver.car_id == null) {
                this.$bvModal.show('modalDriverCar')
            }else {
                this.$bvModal.show('modalCarEdit')
            }
        },
        setSelected1() {
            this.driver.district_id = this.district_select.id
        },
        setSelected3() {
            this.driver.passport_office_id = this.passport_select.id
        },
        fuelTypes() {
            this.$http.get('cars/fuel-types')
                .then((res) => {
                    this.fuel_types = res.data
                })
        },
        Dates(){
            let curr = new Date()
            let max = new Date(curr.getTime() - 567993600000)
            let a = new Date(max).toISOString().split('T')[0]
            let b = new Date().toISOString().split('T')[0]
            this.currDate = a
            this.DateValidator = b
            this.opened()
            this.fuelTypes()
        }
    }
}
</script>

<style scoped>
.border-color{
    border: 1px solid #ccc;
    border-radius: 5px;
    width: auto;
}
.borders{
    border: 1px solid #D9D9D9;
}
.without{
    font-size: 18px;
    padding-left: 5px;
}
.check{
    display: flex;
}
@media (max-width: 993px) {
    .adaptiv {
        display: block !important;
    }
    .car{
        margin-right: 0 !important;
    }
}
</style>